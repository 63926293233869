<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" @scrollToSection="scrollToSection" />
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{ 'active': menuVisible }">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class='header-container'>
          <Header :searchedProductText="searchedProductText" @openSignInModal="openSignInModal"
            @openSignUpModal="openSignUpModal" @logout="logout" @scrollToSection="scrollToSection" />
        </div>
        <router-view @logout="logout" @getProductPageContent="getProductPageContent" @formatDate="formatDate"
          :currency="internalCurrencyName" @goToProduct="goToProduct" :productPageItem="productPageItem" :models="models"
          @openReadyModal="openReadyModal" @openTopUpModal="openTopUpModal" />
        <div class='footer-container'>
          <Footer v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')"
            @scrollToSection="scrollToSection" />
        </div>
        <transition name="fade">
          <SignInModal v-if="signInModalIsVisible" @closeSignInModal="closeSignInModal"
            @openSignUpModal="openSignUpModal" @login="login" @forgotPass="forgotPass" />
        </transition>
        <transition name="fade">
          <SignUpModal v-if="signUpModalIsVisible" @closeSignUpModal="closeSignUpModal"
            @openSignInModal="openSignInModal" @registration="registration" />
        </transition>
        <transition name="fade">
          <TopUpModal v-if="topUpModalVisible" @openOrderModal="openOrderModal" :topUpAmount="topUpAmount" :itemType="itemType" />
        </transition>
        <transition name="fade">
          <OrderModal :orderData="orderData" v-show="orderModalIsVisible" @orderSubmit="orderSubmit" />
        </transition>
        <transition name="fade">
          <ReadyModal v-if="readyModalVisible" />
        </transition>

        <div class="modal-overlay" v-if="cookesModalIsVisible && !isOnCookiePolicyPage">
          <div class="cookies-modal">
            <div class="desc">
              {{ $t(`We use cookies on our website to enhance your experience. Before you proceed with using our Website, please get acquainted with the way we handle a User's data and the use of Usage of`) }} <a href="#" @click.prevent="goToPage('cookie-policy')">{{ $t(`cookie policy`) }}</a>.
            </div>
            <button class="button" @click="acceptCookies">{{ $t(`Accept`) }}</button>
            <button class="button" @click="declineCookies">{{ $t(`Reject`) }}</button>
          </div>
        </div>
      </div>
    </transition>

    <ChatBot />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import SignInModal from './components/SignInModal.vue'
import SignUpModal from './components/SignUpModal.vue'
import TopUpModal from './components/TopUpModal.vue'
import ReadyModal from './components/ReadyModal.vue'
import { scroller } from 'vue-scrollto/src/scrollTo'
import OrderModal from './components/OrderModal.vue'
import { mapActions, mapGetters } from "vuex";
import ChatBot from './components/ChatBot.vue'


export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    SignInModal,
    SignUpModal,
    TopUpModal,
    ReadyModal,
    OrderModal,
    ChatBot
  },
  data: function () {
    return {
      pageLoaded: false,
      menuVisible: false,
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      error: '',
      successMessage: '',
      orderHistory: [],
      promtHistory: [],
      depositHistory: [],
      stableDiffusion: [],
      dalle2: [],
      chatGpt: [],
      searchedProductText: '',
      totalSum: '0.00',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: '',
      imgDomain: '',
      textPageList: '',
      hostname: '',
      models: [],
      categories: [],
      categoryOptions: [],
      modelOptions: [],
      packs: [],
      activePackProducts: [],
      activePack: {},
      productList: [],
      sort: '',
      activeCategories: [],
      activeModels: [],
      dropdownCategories: [],
      topUpModalVisible: false,
      readyModalVisible: false,
      readyPromt: false,
      readyPack: false,
      tranding: [],
      readyProduct: '',
      settings: '',
      socialLinks: [],
      productPageType: '',
      orderModalIsVisible: false,
      orderData: {},
      isValidEmail: true,
      allPaymentMethods: [], // Store all payment methods 
      availablePaymentMethods: [], // Store available payment methods for the current order
      appDomain: '',
      cookesModalIsVisible: false,
      topUpAmount: 0,
      internalCurrencyName: 'StormBills',
      internalCurrencyImage: require('@/images/storm-bills.png'),
      exchangeRate: 1,
      itemType: null,
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
        this.getPromtHistory();
        this.getDepositHistory();
      }
    },
    currency() {
      this.getSettings();
    }
  },
  created() {
    let self = this;
    window.addEventListener('click', (e) => {
      const dropdown = document.querySelector(".dropdown");
      const categoryList = document.querySelector(".category-list");
      if (dropdown && categoryList) {
        if (!dropdown.contains(e.target) && !categoryList.contains(e.target)) {
          self.clearDropdownCategories();
        }
      }
    })
  },
  methods: {
    ...mapActions('app', ['fetchCurrencies']),
    ...mapActions('user', ['changeIsAuth', 'changeEmailVerified']),
    ...mapActions('app',['fetchLanguages']),
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
    checkAuthStatus() {
      this.$http.get(process.env.VUE_APP_API + 'is-auth')
        .then((res) => {
          this.changeIsAuth(res.data.authenticated);
          this.changeEmailVerified(res.data.emailVerified);

          if (res.data.authenticated) {
            this.getOrderHistory();
            this.getPromtHistory();
            this.getDepositHistory();
          }

          if (res.data.authenticated && !res.data.emailVerified) {
            this.$router.push({ name: 'EmailVerificationNotice' });
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    orderSubmit(data, type) {
      data.paymentType = type;
      const apiUrl = data.itemType ? 'deposit/bundle-checkout' : 'deposit/checkout';
      this.$http.post(process.env.VUE_APP_API + apiUrl, data)
        .then((res) => {
          if (res.data.status == 'ERROR') {
            this.$router.push({ name: "PaymentFaliPage" });
            this.orderModalIsVisible = false;
          } else {
            window.location = res.data.redirect_url;
          }
        })
        .catch((res) => {
          // this.error = res.response.data.message;
          this.$toast.warning(res.response.data.message, { timeout: 2000 });
          // this.orderModalIsVisible = false;
        })
    },
    openOrderModal({ orderData, paymentMethods }) { 
      this.orderModalIsVisible = true; 
      this.availablePaymentMethods = paymentMethods; 
      this.orderData = orderData; 
    },
    closeOrderModal() {
      this.orderModalIsVisible = false;
    },
    getPacks() {
      this.packs = [];
      this.activePack = {};
      this.$http.get(process.env.VUE_APP_API + 'packs')
        .then((res) => {
          this.packs = res.data.payload;
          this.activePack = this.packs[0];
          this.getActivePackProducts(this.activePack)
        })
        .catch(() => {

        })
    },
    getActivePackProducts(item) {
      this.$http.get(process.env.VUE_APP_API + 'packs/' + item.id)
        .then((res) => {
          let self = this;
          self.activePack = item;
          self.activePackProducts = '';
          setTimeout(function () {
            self.activePackProducts = res.data.payload;
          }, 300)

        })
        .catch(() => {

        })
    },
    scrollToSection(section) {
      if ((this.$route.name != 'Home') && section != 'page') {
        this.$router.push({ path: '/' });
      } else {
        this.closeMenu();
      }

      setTimeout(() => {
        const firstScrollTo = scroller()
        firstScrollTo('#' + section, 1000)
      }, 1000)
    },
    getTranding() {
      this.tranding = [];
      this.$http.get(process.env.VUE_APP_API + 'products/trending')
        .then((res) => {
          this.tranding = res.data.payload
        })
        .catch(() => {

        })
    },
    buyPack() {
      let data = {
        'pack_id': this.activePack.id
      }
      this.$http.post(process.env.VUE_APP_API + 'purchase/pack', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = 'Success';
            this.$router.push({ path: '/profile/order-history' });


          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
          this.error = res.response.data.message
        })
    },
    topUpSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'deposit/checkout', data)
        .then((res) => {
          if (res.data.status == "OK") {
            window.location = res.data.redirect_url;
          } else {
            this.error = res.response.data.message
          }
        })
        .catch((res) => {
          this.error = res.response.data.message
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        })
    },
    buyProduct() {
      let data;
      if (this.readyProduct) {
        data = {
          'prompt_id': this.readyProduct.id
        }
      } else {
        data = {
          'prompt_id': this.productPageItem.id
        }
      }

      this.$http.post(process.env.VUE_APP_API + 'purchase/prompt', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = 'Success';
            this.$router.push({ path: '/profile/order-history' });
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
          this.error = res.response.data.message
        })
    },
    openReadyModal(item, product) {
      this.readyProduct = product;
      if (item === 'pack') {
        this.activePack = product;
        this.setReadyPack();
      } else {
        this.setReadyPromt();
      }
      this.readyModalVisible = true;
    },
    setReadyPromt() {
      this.readyPromt = true;
    },
    setReadyPack() {
      this.readyPack = true;
    },
    closeReadyModal() {
      this.readyPromt = false;
      this.readyPack = false;
      this.successMessage = '';
      this.error = '';
      this.readyModalVisible = false;
    },
    openTopUpModal(amount = 0.0, itemType  = null) {
      this.topUpAmount = amount;
      this.topUpModalVisible = true;
      this.itemType = itemType
    },
    closeTopUpModal() {
      this.topUpAmount = 0;
      this.topUpModalVisible = false;
    },
    clearDropdownCategories() {
      this.dropdownCategories = [];
    },
    openModelDropdown(model) {
      this.dropdownCategories = this.categories.filter(item => item.parent_id === model.id);
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openSignInModal() {
      this.closeMenu();
      this.clearError();
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
    },
    openSignUpModal() {
      this.clearError();
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
    },
    clearError() {
      this.error = '';
    },
    closeSignUpModal() {
      this.signUpModalIsVisible = false;
      this.clearError();
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    async login(data) {
      try {
        // Fetch CSRF token and set it as a cookie
        await this.fetchCSRFToken();

        // Proceed with login
        const res = await this.$http.post(process.env.VUE_APP_API + 'login', data);

        if (res.data.status == "OK") {
          await this.changeIsAuth(true);
          await this.changeEmailVerified(!!res.data.payload.email_verified);
          this.closeSignInModal();
          this.getOrderHistory();
          this.getPromtHistory();
          this.getDepositHistory();
        } else {
          this.error = res.data.message;
        }
      } catch (error) {
        if (error.response) {
          this.error = error.response.data.message;
        } else {
          this.error = "An error occurred during login.";
        }
      }
    },
    async fetchCSRFToken() {
      try {
        await this.$http.get(process.env.VUE_APP_DOMAIN + 'sanctum/csrf-cookie');
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
      }
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = res.data.message;
            this.error = ''
            setTimeout(() => {
              self.successMessage = '';
            }, 2000)
          } else {
            this.successMessage = '';
            this.error = res.response.data.message
          }
        })
        .catch((error) => {
          this.error = error.response.data.message
        })
    },
    async registration(data) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(data.email);

      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else if (!this.isValidEmail) {
        this.error = 'Email format failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
          .then(async (res) => {
            if (res.data.status == "OK") {
              this.clearError();
              await this.changeIsAuth(true)
              self.closeSignUpModal();
            } else {
              this.error = res.data.message
            }
          })
          .catch((res) => {
            this.error = res.response.data.message;
          })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
        .then(async () => {
          if (this.$route.name != 'Home') {
            this.$router.push({ path: '/' })
          }

          await this.changeIsAuth(false)
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        })
    },
    getOrderHistory() {
      this.orderHistory = [];
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
        .then((res) => {
          this.orderHistory = res.data.payload
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        })
    },
    getPromtHistory() {
      this.promtHistory = [];
      this.$http.get(process.env.VUE_APP_API + 'user/prompts')
        .then((res) => {
          this.promtHistory = res.data.payload
        })
        .catch((res) => {
          console.log('res:', res);
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        })
    },
    getDepositHistory() {
      this.depositHistory = [];
      this.$http.get(process.env.VUE_APP_API + 'deposit/history')
        .then((res) => {
          this.depositHistory = res.data.payload
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        })
    },
    formatDate(num) {
      let objectDate = new Date(num);
      let day = objectDate.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    goToProduct(item) {
      this.productPageType = '';
      this.$router.push({ path: '/products/' + item.product_type + '/' + item.id, params: { data: item.id } });
      this.getProductPageContent(item.id);
      this.productPageType = item.product_type
    },
    getProductPageContent(id, type) {
      this.productPageItem = '';
      if (type) {
        if (type == 'pack') {
          this.$http.get(process.env.VUE_APP_API + 'packs/' + id + '/info')
            .then((res) => {
              this.productPageItem = res.data.payload;
            })
            .catch((res) => {
              if (res.response.status === 401) {
                this.openSignInModal();
              }
            })
        } else {
          this.$http.get(process.env.VUE_APP_API + 'products/' + id + '/info')
            .then((res) => {
              this.productPageItem = res.data.payload;
            })
            .catch(() => {

            })
        }
      }
    },
    goToPage(item) {
      let newItem;
      if (item == 'privacy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
            newItem = this.textPageList[i].id;
          }
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('terms')) {
            newItem = this.textPageList[i].id;
          }
        }
      }
      else if (item === 'cookie-policy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i].id;
            break;
          }
        }
      }
      else {
        newItem = item
      }
      this.$router.push({ path: '/pages/' + newItem, params: { data: newItem } })
      this.getTextPageContent(newItem);
      this.closeSignUpModal();
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        })
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
        .then((res) => {
          this.textPageList = res.data.payload
        })
        .catch(() => {

        })
    },
    getSettings() {
      this.settings = [];
      this.$http.get(process.env.VUE_APP_API + 'settings')
        .then((res) => {
          this.allPaymentMethods = res.data.payload.payment_methods;
          this.settings = res.data.payload;
          this.internalCurrencyName = res.data.payload.internal_currency_name;
          this.exchangeRate = res.data.payload.exchange_rate;
        })
        .catch(() => { })
    },
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
        .then(({ data }) => {
          this.socialLinks = data.payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted: async function () {
    await this.fetchLanguages();
    await this.fetchCurrencies();
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname)
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.appDomain = process.env.VUE_APP_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();
    self.getSettings();
    self.getSocialLinks();

    // self.$http.get(process.env.VUE_APP_API + 'is-auth')
    // .then(async (res) => {
    //     console.log('auth: ' + res.data.authenticated);
    //     self.changeIsAuth(!!res.data.authenticated)
    //
    //     if (res.data.authenticated) {
    //       self.getOrderHistory();
    //       self.getPromtHistory();
    //       self.getDepositHistory();
    //     }
    // })
    // .catch((res) => {
    //     if(res.response.status === 401) {
    //       this.openSignInModal();
    //     }
    // })

    self.checkAuthStatus();

    this.getPacks();
    this.getTextPages();
    this.getTranding();
  },
  computed: {
    ...mapGetters('user', ['isAuth']),
    ...mapGetters('app', ['currency', 'currencySymbol']),
    isOnCookiePolicyPage() {
      return this.$route.path.includes('/pages/1');
    }
  }
}
</script>
